import PropTypes from "prop-types"
import React from "react"

import * as styles from './header.module.css'

export default class Header extends React.Component {
  render() {
    let headerOpt = this.props.headerOpt;
    let siteTitle = this.props.siteTitle;

    /*
      If headerOpt == 1 --> Header with background
      If headerOpt == 2 --> Header with no background
      If headerOpt == 3 --> No Header
    */

    if(headerOpt === 1)
    {
      return (
        <>
        <header className={styles.header}>
            <div className={styles.div}>
              <h1 className={styles.homeLink}> {siteTitle} </h1>        
            </div>
          </header>
        </>
      )
    }

    else if(headerOpt === 2) {
      return (
        <>
        <header className={styles.header2}>
            <div className={styles.div2}>
              <h1 className={styles.homeLink2}> {siteTitle} </h1>        
            </div>
          </header>
        </>
      )
    }

    else {
      return ( null )
    }
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  headerOpt: 1,
}

