// extracted by mini-css-extract-plugin
export const slidingSideNavigation = "navigation-module--slidingSideNavigation--przYw";
export const slideNavigationList = "navigation-module--slideNavigationList--20gEl";
export const menuButton = "navigation-module--menuButton--2kL9N";
export const slideNavigationItem = "navigation-module--slideNavigationItem--2Gm0S";
export const navigationParent = "navigation-module--navigationParent--3TCO2";
export const logo = "navigation-module--logo--3Yqkb";
export const navigationList = "navigation-module--navigationList--2yHMC";
export const navigationItem = "navigation-module--navigationItem--3NUGy";
export const socialNavList = "navigation-module--socialNavList--nQIDm";
export const socialNavItem = "navigation-module--socialNavItem--1bH4z";
export const socialImages = "navigation-module--socialImages--30LaN";
export const swipeSocialNavList = "navigation-module--swipeSocialNavList--2_oRt";
export const swipeSocialNavItem = "navigation-module--swipeSocialNavItem--3j707";
export const logoImage = "navigation-module--logoImage--ocsFp";