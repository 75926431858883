import React from 'react'
import { Link } from 'gatsby'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
// import Img from "gatsby-image"

import * as styles from './navigation.module.css'

export default function Navigation() {
    const [state, setState] = React.useState({
        left: false,
    });
    
    const toggleDrawer = (side, open) => {
        setState({state, [side]: open });
    };

    const homeLink = "/";
    const blogLink = "/blog/"
    const aboutLink = "/about/"

    const homeNavTitle = "Home"
    const blogNavTitle = "Blog"
    const aboutPageNavTitle = "About Me"

    
    return (
        <>
            <nav role="navigation" className={styles.navigationParent}>

                <Button onClick={() => toggleDrawer('left', true)} className={styles.menuButton} aria-label="Side Menu"><MenuIcon/></Button>

                <Link to={homeLink} className={styles.logo}><img loading="lazy" className={styles.logoImage} src={"/icons/gevdevlogo.png"} width="65px" height="65px" alt="Logo"/></Link>

                <div className={styles.navigationList}>
                    <Link className={styles.navigationItem} to={homeLink}>{homeNavTitle}</Link>
                    <Link className={styles.navigationItem} to={blogLink}>{blogNavTitle}</Link>
                    <Link className={styles.navigationItem} to={aboutLink}>{aboutPageNavTitle}</Link>
                </div>

                <div className={styles.socialNavList}>
                    {/* <a href="https://facebook.com/GevDevPage/" className={styles.socialNavItem}><img loading="lazy" className={styles.socialImages} src={"/icons/facebook.svg"} alt="Facebook Icon"/></a> */}
                    <a href="https://instagram.com/GevDev/" className={styles.socialNavItem}><img loading="lazy" className={styles.socialImages} src={"/icons/instagram.svg"} alt="Instagram Icon"/></a>
                    <a href="https://twitch.tv/GevDev/" className={styles.socialNavItem}><img loading="lazy" className={styles.socialImages} src={"/icons/twitch.svg"} alt="Twitch Icon"/></a>
                    <a href="https://twitter.com/GevDev/" className={styles.socialNavItem}><img loading="lazy" className={styles.socialImages} src={"/icons/twitter.svg"} alt="Twitter Icon"/></a>
                    <a href="https://www.youtube.com/channel/UCveU_coQyHLkcZxxAlBM4DQ/" className={styles.socialNavItem}><img loading="lazy" className={styles.socialImages} src={'/icons/youtube.svg'} alt="YouTube Icon"/></a>
                </div>
            </nav>

            <SwipeableDrawer open={state.left} onClose={() => toggleDrawer('left', false)} onOpen={() => toggleDrawer('left', true)}>
                <nav role="navigation" className={styles.slidingSideNavigation}>
                    <div className={styles.slideNavigationList}>
                        <Link className={styles.slideNavigationItem} to={homeLink}>{homeNavTitle}</Link>
                        <Link className={styles.slideNavigationItem} to={blogLink}>{blogNavTitle}</Link>
                        <Link className={styles.slideNavigationItem} to={aboutLink}>{aboutPageNavTitle}</Link>
                        <div className={styles.swipeSocialNavList}>
                            <a href="https://instagram.com/GevDev/" className={styles.swipeSocialNavItem}><img loading="lazy" className={styles.socialImages} src={"/icons/instagram.svg"} alt="Instagram Icon"/></a>
                            <a href="https://twitch.tv/GevDev/" className={styles.swipeSocialNavItem}><img loading="lazy" className={styles.socialImages} src={"/icons/twitch.svg"} alt="Twitch Icon"/></a>
                            <a href="https://twitter.com/GevDev/" className={styles.swipeSocialNavItem}><img loading="lazy" className={styles.socialImages} src={"/icons/twitter.svg"} alt="Twitter Icon"/></a>
                            <a href="https://www.youtube.com/channel/UCveU_coQyHLkcZxxAlBM4DQ/" className={styles.swipeSocialNavItem}><img loading="lazy" className={styles.socialImages} src={'/icons/youtube.svg'} alt="YouTube Icon"/></a>
                        </div>
                    </div>
                </nav>
            </SwipeableDrawer>
        </>
    )
}

