/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Seo from "../components/seo"
import Navigation from "./navigation"
import Header from "./header"
import Footer from "./footer"

import "./layout.css"
// import * as styles from "./layout.module.css"

const Layout = ({ headerOpt, pageTitle, pageWidth, SEODescription, children }) => {
  return (
    <>
      <Seo title={pageTitle} description={SEODescription}/>
      <Navigation/>
      <Header headerOpt={headerOpt} siteTitle={pageTitle}  />

      <div
        style={{
          margin: `0 auto`,
          maxWidth: pageWidth,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
          position: `relative`
        }}
      >
        <main>{children}</main>
        <Footer/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  pageWidth: 960,
}


export default Layout


