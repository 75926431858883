import React from "react"
import {Link} from "gatsby"

import * as styles from "./footer.module.css"

export default class Footer extends React.Component {
  render() {
        return (
            <>
                <footer className={styles.layoutFooter}>
                    © {new Date().getFullYear()}
                    <br/>
                    <h5>Social Links</h5>
                    {/* <p><Link to="https://instagram.com/GevDev">YouTube</Link></p> */}
                    {/* <p><Link to="https://instagram.com/GevDev">Twitch</Link></p> */}
                    {/* <p><Link to="https://instagram.com/GevDev">Twitter</Link></p> */}
                    <p><Link to="https://instagram.com/GevDev">Instagram</Link></p>
                </footer>
            </>
        )
    }
}